<template>
  <div>
    <div class="row justify-content-end">
      <div class="col">
        <label for="">Client</label>
        <input
          type="text"
          v-model="search_value"
          @keyup="search(search_value)"
          class="form-control"
        />
      </div>
      <div class="col-auto">
        <label for=""></label>

        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i> Actualiser
        </button>
      </div>
      <div class="col-auto">
        <label for=""></label>

        <button @click="print()" class="btn btn-secondary border">
          <i class="bi bi-printer fs-4"></i> Imprimer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom Complet</th>
          <th scope="col" class="text-center">Total impayé</th>
          <th scope="col" class="text-center">État de crédit</th>
          <th scope="col" class="text-center">Total TTC</th>
          <th scope="col">Du</th>
          <th scope="col">Au</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(credit, index) in credits" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ credit.supplier_reference }}
          </td>
          <td>
            <span v-if="credit.supplier">
              {{ credit.supplier.fullName }}
            </span>
          </td>
          <td class="text-end">
            <span class="text-danger">
              <strong>
                {{
                  parseFloat(credit.totalUnpaid).toFixed(2) | formatWithSpaces
                }}
              </strong>
            </span>
          </td>
          <td>
            <span v-if="credit.supplier">
              <span v-if="credit.supplier.maxUnpaid > 0">
                <input
                  type="range"
                  :min="0"
                  :max="credit.supplier.maxUnpaid"
                  :step="1"
                  :value="credit.totalUnpaid"
                  :disabled="true"
                  :class="
                    credit.totalUnpaid >= credit.supplier.maxUnpaid
                      ? 'bg-danger border'
                      : ''
                  "
                />
                {{ credit.supplier.maxUnpaid }}
                <span
                  v-if="credit.totalUnpaid > credit.supplier.maxUnpaid"
                  class="bg-danger rounded px-2 py-1 text-white"
                >
                  +
                  {{
                    parseFloat(
                      credit.totalUnpaid - credit.supplier.maxUnpaid
                    ).toFixed(2)
                  }}
                </span>
              </span>
            </span>
          </td>
          <td class="text-end">
            <span class="text-primary">
              <strong>
                {{ parseFloat(credit.totalTTC).toFixed(2) | formatWithSpaces }}
              </strong>
            </span>
          </td>
          <td>
            <span>
              {{ credit.firstDate }}
            </span>
          </td>
          <td>
            <span>
              {{ credit.lastDate }}
            </span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-end">
            <span class="text-end bg-danger rounded px-2 py-1 text-white">
              {{ parseFloat(totalUnpaidSum).toFixed(2) | formatWithSpaces }}
            </span>
          </td>
          <td class="text-end">
            <span class="text-end bg-primary rounded px-2 py-1 text-white">
              {{ parseFloat(totalTTCSum).toFixed(2) | formatWithSpaces }}
            </span>
          </td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
    };
  },
  computed: {
    ...mapGetters("credit", {
      credits: "getCreditsSuppliers",
      totalUnpaidSum: "getTotalUnpaidSum",
      totalTTCSum: "getTotalTTCSum",
    }),
  },
  mounted() {
    this.$store.dispatch("credit/fetchCreditsSuppliers");
  },
  methods: {
    async search(value) {
      return await this.$store.dispatch("credit/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("credit/fetchCreditsSuppliers");
    },
    async print() {
      return await this.$store.dispatch("credit/printAll");
    },
  },
  filters: {
    formatWithSpaces(value) {
      // Convert the number to a string and apply spaces as thousands separators
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
